$text-body-size: 16px;
$text-base-size: 1rem;
$text-header1-size: 2rem;
$text-header3-size: 1.3rem;
$text-body-title-size: 1.1rem;
$text-button-size: 0.8rem;
$text-label-size: 0.75rem;
$text-graph-value-size: 36px;
$text-input-size: 1.4rem;

$border-radius-size: 3.5px;

$global-padding: 40px;
$global-padding-mobile: 15px;

$max-width: 640px;
$max-width-large: calc($max-width * 1.2);
$max-width-in-between: calc($max-width / 1.2);

$nav-bar-height: 80px;
$nav-bar-height-mobile: 50px;
$nav-bar-spacing: 2px;

$side-nav-width: 220px;

$break-very-small: calc($max-width / 3);
$break-small: calc($max-width / 2);
$break-large: $max-width;
$break-larger: calc($max-width * 1.5);
$break-very-large: calc($max-width * 2);

$vertical-break-tall: 1300px;
$vertical-break-medium: 1000px;
$vertical-break-short: 700px;

$content-top-margin: 10px;

$border-size: 1px;
$input-margin-bottom: 10px;
$input-base-padding: 10px;
$input-border-size: 2px;
$input-spacing: 10px;
$input-base-width: 250px;
$table-column-spacing: 10px;

$loading-indicator-border-size: 6px;
$loading-indicator-size: 36px;

@mixin respond-to($media) {
  @if $media==small-phone {
    @media only screen and (max-width: $break-very-small) {
      @content;
    }
  }

  @if $media==phone {
    @media only screen and (max-width: $break-small) {
      @content;
    }
  }

  @else if $media==mobile {
    @media only screen and (max-width: $break-large) {
      @content;
    }
  }

  @else if $media==tablet {
    @media only screen and (min-width: $break-small + 1) and (max-width: $break-large - 1) {
      @content;
    }
  }

  @else if $media==small-desktop {
    @media only screen and (min-width: $break-large + 1) and (max-width: $break-larger - 1) {
      @content;
    }
  }

  @else if $media==desktop {
    @media only screen and (min-width: $break-large) and (max-width: $break-very-large - 1) {
      @content;
    }
  }

  @else if $media==large-desktop {
    @media only screen and (min-width: $break-very-large) {
      @content;
    }
  }
}

@mixin respond-to-vertical($media) {
  @if $media==tall {
    @media only screen and (min-height: $vertical-break-tall) {
      @content;
    }
  }

  @else if $media==taller {
    @media only screen and (min-height: $vertical-break-medium + 1) and (max-height: $vertical-break-tall - 1) {
      @content;
    }
  }

  @else if $media==shorter {
    @media only screen and (min-height: $vertical-break-short + 1) and (max-height: $vertical-break-medium) {
      @content;
    }
  }

  @else if $media==short {
    @media only screen and (max-height: $vertical-break-short) {
      @content;
    }
  }
}

@mixin max-width {
  max-width: $max-width;
  width: 100%;

  @include respond-to(large-desktop) {
    max-width: $max-width-large;
  }
}

@mixin graph-height($height: 50vh) {
  @include respond-to-vertical(tall) {
    height: $height;
    max-height: calc($vertical-break-tall / 2);
  }

  @include respond-to-vertical(taller) {
    height: calc($height - 5vh);
    min-height: calc($vertical-break-medium / 2);
  }

  @include respond-to-vertical(shorter) {
    height: calc($height - 10vh);
    min-height: calc($vertical-break-short / 2);
  }

  @include respond-to-vertical(short) {
    height: calc($height - 10vh);
    min-height: calc($vertical-break-short / 2.5);
  }
}
