@import 'main';

.content-subtext {
  @include color-var(color, minimal-text-color);

  font-size: 0.8rem;
  margin-top: 0.8rem;
  padding: 0 20px;

  &:first-child {
    margin-top: 0;
  }

  a {
    @include color-var(color, action-color);
    font-weight: bold;
  }
}
