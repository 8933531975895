@import 'main';

.content-list {
  @include color-var(color, secondary-text-color);

  list-style-position: outside;
  list-style-type: none;
  list-style: none;
  padding: 0 20px 0 40px;

  li {
    margin-bottom: 0.4em;
    position: relative;

    &:before {
      content: '⌇';
      font-size: 1em;
      left: -25px;
      position: absolute;
      top: -0.1em;
    }
  }
}
