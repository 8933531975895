// Base colors
$white: #fff;
$black: #000;

$black90: #1c1e21;
$black80: #2b2f34;
$black70: #4a4d51;
$black60: #7c8084;
$black40: #a1a7ae;
$black20: #c1c7cd;
$black10: #e1e4ea;
$black05: #f4f4f4;

$blacka70: rgba(10, 10, 10, 0.7);
$blacka30: rgba(10, 10, 10, 0.3);
$blacka15: rgba(10, 10, 10, 0.15);
$blacka05: rgba(10, 10, 10, 0.05);
$whitea70: rgba(255, 255, 255, 0.7);
$whitea30: rgba(255, 255, 255, 0.3);
$whitea15: rgba(255, 255, 255, 0.15);
$whitea05: rgba(255, 255, 255, 0.05);

$red: #ff356f;
$blue: #2dbff1;
$green: #43d67d;
$dark-blue: #16ace0;

// Color schemes
$light-scheme: ( // Universal
good-color: $green,
neutral-color: $blue,
brand-color: $red,
bad-color: $red,
action-color: $blue,
action-selected-color: $dark-blue,
inverse-text-color: $white,
inverse-content-color: $white,
// Changed by Scheme
background-color: $white,
primary-text-color: $black90,
secondary-text-color: $black70,
minimal-text-color: $black60,
stroke-color: $blacka05,
selected-background-color: $black05,
separation-color: $blacka15,
inverse-stroke-color: $whitea30,
);

$dark-scheme: ( // Changed by Scheme
background-color: $black90,
primary-text-color: $white,
secondary-text-color: $black20,
minimal-text-color: $black60,
stroke-color: $whitea05,
selected-background-color: $black80,
separation-color: $whitea15,
inverse-stroke-color: $blacka30,
);

// "Private" mixin to set scheme css vars
@mixin set-scheme-vars($scheme) {

  @each $key-name,$color in $scheme {
    --#{$key-name}: #{$color};
  }
}

// "Public" mixin to get schemed css vars with fallbacks to light scheme
@mixin color-var($property, $key-name) {
  #{$property}: map-get($light-scheme, $key-name); //fallback may already be done by postcss
  #{$property}: var(--#{$key-name}, map-get($light-scheme, $key-name)); //fallback may already be done by postcss
}
