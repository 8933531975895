@import 'main';


.layout {
  @include max-width;
  @include color-var(background-color, background-color);
  @include color-var(color, primary-text-color);

  box-sizing: border-box;
  font-size: $text-body-size;
  grid-area: search;
  margin: auto;
  padding: 20px;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;

  @include respond-to(mobile) {
    margin: 0;
    padding: 0;
    width: 100%;
    min-width: 1%;
  }

  &--centered {
    height: 100%;
  }
}

body,
html {
  @include color-var(background-color, background-color);
  @include default-font;

  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
  position: relative;
  scrollbar-color: $white $black;
  scrollbar-width: thin;
}

body {
  overflow-y: scroll;
}

*,
*::before,
*::after {
  box-sizing: inherit;
  padding: 0;
  margin: 0;
  color: inherit;
  text-decoration: inherit;
}

#root {
  height: 100%;
  min-height: 100%;
  position: relative;
}

// Add scheme css vars to root
:root {
  color-scheme: light dark;

  // Set base scheme (light)
  @include set-scheme-vars($light-scheme);

  // Set dark scheme
  @media (prefers-color-scheme: dark) {
    @include set-scheme-vars($dark-scheme);
  }
}

[data-theme="dark"] {
  @include set-scheme-vars($dark-scheme);
}
