@import 'main';

.content-body {
  @include color-var(color, secondary-text-color);

  margin-bottom: 0.8rem;
  padding: 0 20px;

  &:last-child {
    margin-bottom: 0;
  }

  a {
    @include color-var(color, action-color);
  }

  ul {
    list-style-position: inside;
  }
}
