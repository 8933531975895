@import 'main';

.tool {
  @include reset-button;

  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
  margin-right: 20px;
  overflow: visible;
  width: 24px;

  svg {
    max-width: 24px;
    max-height: 24px;
  }

  &:focus {
    svg path {
      @include color-var(fill, action-color);
    }
  }
}
