@import 'main';

.content-page-title {
  @include color-var(color, bad-color);
  @include color-var(border-top-color, stroke-color);

  border-top-style: solid;
  border-top-width: 0;
  font-size: 2rem;
  font-weight: bold;
  letter-spacing: -0.025em;
  line-height: 2rem;
  margin-bottom: 0.2em;
  margin-top: 2em;
  padding: 0 20px;

  @include respond-to(mobile) {
    margin-top: 0.2em;
  }
}
