@import 'main';

.dictionary-entry {
  &__result {
    @include color-var(color, secondary-text-color);
    @include color-var(border-bottom-color, stroke-color);

    padding: 20px;
    border-bottom-width: 1px;
    border-bottom-style: solid;

    &--exact {
      @include color-var(background-color, selected-background-color);

      border-radius: $border-radius-size;
      border-bottom-color: transparent;
    }

    &--first {
      margin-top: 10px;
    }

    &--last {
      border-bottom-color: transparent;
    }
  }

  &__title {
    @include color-var(color, primary-text-color);
  }

  &__interlink {
    cursor: pointer;

    &:hover {
      text-decoration: underline;
    }
  }

  &__wordentry {
    margin-top: 10px;
    line-height: 1.4em;

    &--H1 {
      @include color-var(color, primary-text-color);

      font-size: 1.1rem;
      font-weight: bold;
    }

    &--H1A {
      font-size: 1.0rem;
    }

    &--H2 {
      @include color-var(color, primary-text-color);

      font-weight: bold;
    }

    &--H2A,
    &--H2B {}
  }

  &__element {
    &--s {
      color: $blue;
    }

    &--lex {
      font-style: italic;
      font-weight: bold;
    }
  }
}
