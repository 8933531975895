@import 'main';

.selector {
  @include color-var(background-color, action-color);
  @include color-var(color, inverse-text-color);

  align-items: center;
  appearance: none;
  background-image: url('../image/icon-expand-white.svg');
  background-position: right 10px center;
  background-repeat: no-repeat;
  background-size: 12px;
  border-radius: 3px;
  border: 0;
  box-sizing: border-box;
  display: flex;
  font-size: 1rem;
  font-weight: bold;
  margin: 7px;
  outline: 0;
  padding: 4px 25px 4px 10px;
  stroke: 0;

  &:first-child {
    margin-left: 0;
  }

  &:last-child {
    margin-right: 0;
  }
}
