@import 'main';

$element-padding: 9px 20px 9px;
$element-padding-mobile: 9px 8px 9px 20px;

.nav-bar-search {
  @include max-width;

  display: flex;
  position: relative;
  border-radius: 0 $border-radius-size $border-radius-size 0;
  grid-area: search;
  justify-content: baseline;

  @include respond-to(mobile) {
    margin: 0;
    padding: 0;
    width: 100%;
    min-width: 1%;
  }

  &__container {
    @include color-var(background-color, selected-background-color);

    width: 100%;
    border-radius: $border-radius-size;
    display: block;
    margin: 16px 0;
    align-items: center;

    @include respond-to(mobile) {
      padding: 0;
      margin: 0;
    }
  }

  &__input {
    @include color-var(background-color, selected-background-color);
    @include color-var(color, primary-text-color);

    border-radius: $border-radius-size 0 0 $border-radius-size;
    appearance: none;
    border: 0;
    caret-color: $red;
    display: block;
    flex-grow: 1;
    font-size: $text-input-size;
    font-weight: bold;
    margin: 0;
    outline: none;
    padding: $element-padding;
    letter-spacing: -0.03em;

    &:focus {
      @include color-var(background-color, stroke-color);
    }

    ::-ms-clear {
      display: none;
    }

    @include respond-to(mobile) {
      min-width: 0;
      border-radius: 0;
      padding: $element-padding-mobile;
    }
  }

  &__actions {
    @include color-var(background-color, selected-background-color);

    border-radius: 0 $border-radius-size $border-radius-size 0;
    display: flex;
    min-width: 20px;
    align-items: center;

    @include respond-to(mobile) {
      border-radius: 0;
      min-width: auto;
    }
  }

  &__input:focus~&__actions {
    @include color-var(background-color, stroke-color);
  }
}
