@import 'main';

$content-base-padding: 2em;

.content-section {
  box-sizing: border-box;
  height: auto;
  opacity: 1;
  overflow: hidden;
  padding-top: $content-base-padding;
  transition: max-height 0.2s linear, padding 0.2s linear, opacity 0.2s linear;

  &:first-child {
    border-top-width: 0;
    max-height: 20em;
    padding-bottom: $content-base-padding;
    padding-top: 0;

    @include respond-to(mobile) {
      padding-top: $content-base-padding;
    }
  }

  &:last-child {
    padding-bottom: $content-base-padding;
  }

  &--hidden {
    padding-top: 0;
    padding-bottom: 0;

    &:first-child {
      max-height: 0;
      opacity: 0;
      padding: 0;
    }
  }

  &--break {
    @include color-var(border-top-color, stroke-color);
    border-top-width: 1px;
    border-top-style: solid;
  }
}

.content-section+.content-section {
  margin-top: 1em;
}
