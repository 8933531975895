@import 'main';

.nav-bar-search-autocomplete {
  @include color-var(background-color, background-color);

  appearance: none;
  box-sizing: border-box;
  list-style-type: none;
  outline: none;
  padding: 0;
  overflow: auto;
  position: absolute;
  top: 100%;
  width: 100%;
  z-index: 1;

  @include respond-to(mobile) {
    margin-top: 0;
  }

  &__item {
    box-sizing: border-box;
    list-style: none;
    margin: 0;
    width: 100%;
  }

  &__item-button {
    @include color-var(background-color, background-color);
    @include color-var(color, primary-text-color);

    transition-duration: 0s;
    width: 100%;
    appearance: none;
    box-sizing: border-box;
    cursor: pointer;
    padding: 10px;
    text-align: left;
    margin: 0;
    outline: 0;
    border: 0;
    font-size: $text-body-title-size;
    display: flex;
    justify-content: space-between;
    vertical-align: baseline;
    flex-direction: column;

    &:hover {
      @include color-var(background-color, stroke-color);
    }

    &--selected {
      background-color: $blue;
      color: $white;

      .nav-bar-search-autocomplete__item-subscribers {
        color: white;
      }

      &:hover {
        background-color: $blue;
        opacity: 0.9;
      }
    }
  }
}
