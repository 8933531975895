@import 'colors';
@import 'type';
@import 'sizes';

@mixin reset-input {
  @include input-font;
  @include color-var(background-color, selected-background-color);
  @include color-var(color, primary-text-color);

  border-radius: $border-radius-size;
  line-height: $text-body-size;
  padding: $input-base-padding;
  border: 0;
  outline: none;
  background-image: none;
  height: 40px;
  font-weight: bold;
  appearance: none;
  box-sizing: border-box;
  caret-color: auto;

  &:focus {
    border-width: 2px;
    border-color: $blue;
    border-style: solid;
    padding: $input-base-padding - 2px;
  }

  &::placeholder {
    @include input-font;
    @include color-var(color, minimal-text-color);

    font-weight: normal;
  }
}

@mixin reset-button {
  background: none;
  border: 0;
  box-sizing: border-box;
  cursor: pointer;
  display: inline-block;
  margin: 0;
  outline: 0;
  padding: 0;
  text-align: center;
  text-decoration: none;

  &:hover {
    opacity: 0.95;
  }
}

@mixin disable-highlight {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-tap-highlight-color: transparent;
  user-select: none;
}

$grid-expansion-size-normal: minmax(200px, 1024px);
$grid-expansion-size-large: minmax(200px, 1400px);

@mixin grid-layout {
  display: grid;
  grid-template-columns: [nav] $side-nav-width [first] $grid-expansion-size-normal [extent] 1fr [right-margin] $side-nav-width [last];
  grid-template-rows: [head] auto [foot];
  grid-column-gap: $global-padding;

  @include respond-to(small-desktop) {
    grid-template-columns: [nav] $side-nav-width [first] $grid-expansion-size-normal [extent] $side-nav-width [last];
  }

  @include respond-to(large-desktop) {
    grid-template-columns: [nav] ($side-nav-width * 2) [first] $grid-expansion-size-large [extent] auto [right-margin] ($side-nav-width * 2) [last];
  }
}

@mixin mobile-grid-layout {
  display: grid;
  grid-template-columns: [first] auto [last];
  grid-template-rows: [head] auto [foot];
  grid-column-gap: 0;
}

@mixin mobile-nav-grid-layout {
  display: grid;
  grid-template-columns: [first] 1fr [middle] 1fr [last];
  grid-template-rows: [head] $nav-bar-height-mobile [middle] $nav-bar-height-mobile [foot];
  grid-column-gap: 0;
}

@mixin content-grid-layout {
  display: grid;
  grid-auto-rows: minmax(0, auto);
  grid-column-gap: $global-padding;
  grid-template-columns: [nav] $side-nav-width [first] $grid-expansion-size-normal [extent] auto [right-margin] $side-nav-width [last];

  @include respond-to(mobile) {
    grid-template-columns: [first] auto [last];
  }

  @include respond-to(small-desktop) {
    grid-template-columns: [nav] $side-nav-width [first] $grid-expansion-size-normal [extent] $side-nav-width [last];
  }

  @include respond-to(large-desktop) {
    grid-template-columns: [nav] ($side-nav-width * 2) [first] $grid-expansion-size-large [extent] auto [right-margin] ($side-nav-width * 2) [last];
  }
}

@mixin use-narrow-grid {
  grid-column-start: first;
  grid-column-end: extent;

  @include respond-to(mobile) {
    grid-column-end: last;
  }

  @include respond-to(small-desktop) {
    grid-column-end: last;
    margin-right: $global-padding;
  }
}

@mixin use-full-grid {
  grid-column-start: first;
  grid-column-end: last;
  margin-right: $global-padding;

  @include respond-to(mobile) {
    margin-right: 0;
    margin-left: 0;
  }
}

@mixin use-ultra-wide-grid {
  @include use-full-grid;

  margin-left: $global-padding;
  grid-column-start: nav;

  @include respond-to(mobile) {
    grid-column-start: first;
  }
}

@mixin composite-loading {
  &--loading {
    > * {
      opacity: 0.5;
      min-height: $loading-indicator-size;
    }

    > .content-loading-indicator {
      opacity: 1;
    }
  }
}
