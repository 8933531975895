@import 'colors';

@mixin default-font {
  font-family: -apple-system, BlinkMacSystemFont, 'Helvetica', 'Arial', sans-serif;
  font-size: $text-base-size;
  font-weight: normal;
}

@mixin input-font {
  @include default-font;

  font-size: $text-base-size;
}
